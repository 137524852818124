.c-table {
	
	&__row {
		height: 4em;
	}

	&__th {
		padding: 0 0.5em;
		min-width: 100px;
	}

	&__td, &__th {
		letter-spacing: 0.120em;
		font-family: Arial, Helvetica,  'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', "游ゴシック", YuGothic, Meiryo, sans-serif;
	}

	@include mq() {
		&__th {
			font-size: 1.4rem;
		}
	}
}