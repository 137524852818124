$breakpoints: (
  'sm': 'screen and (max-width: 480px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 1280px)',
  'xl': 'screen and (min-width: 1281px)',
) !default;

$breakpoints-min: (
  'sm': 'screen and (min-width: 481px)',
  'md': 'screen and (min-width: 769px)',
  'lg': 'screen and (min-width: 1281px)',
  'xl': 'screen and (min-width: 1281px)',
) !default;


$header-height: 80px;
$header-height-sp: 66px;
$header-inner-width: 85%;

$lp-inner-width: 768px;

$color-primary-text: #000;
$color-secondary-text: #646464;
$color-white-text: #fff;
$color-background-darker: #242424; // dark gray
$color-background-dark: #3f3f3f; // dark gray
$color-background-light: #d2d2d2; // light gray
$color-background-lighter: #c6c6c6; // light gray
$color-background-lightest: #f6f6f6; // light gray
$color-primary: #147D3C; //darkblue
$color-error: #f55; //red
$color-link: #147D3C;

