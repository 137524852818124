.ls-header {
	@include mq-min() {
		display: none;
	}
	@include mq() {
		display: block;
		box-sizing: border-box;
		height: $header-height-sp;
		width: 100%;
		text-align: center;
		background-color: #fff;
		z-index: 10;
		position: fixed;
		top: 0;

		&__inner {
			box-sizing: border-box;
			width: $header-inner-width;
			height: $header-height-sp;
			max-width: $header-inner-width;
			margin: 0 auto;
			text-align: left;
			display: inline-flex;
		}

		&__logo-wrapper {
			line-height: 100px;
    	flex-grow: 1;
			line-height: $header-height-sp;

		}

		&__logo {
			height: 50px;
			width: auto;
			vertical-align: middle;
		}

		&__humburger {
			align-self: center;
		}
	}
}