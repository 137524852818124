html {
	font-size: 10px;
	min-width: 100%;
}

body {
	font-size: 1.4rem;
	font-family: "DIN Condensed", Arial, Helvetica,  'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', "游ゴシック", YuGothic, Meiryo, sans-serif;
}

a {
	transition: all .3s ease;
	color: $color-link;

	&:hover {
		color: $color-link;
		cursor: pointer;
	}
}

