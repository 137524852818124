.c-hamburger {
  width: 30px;
  height: 26px;
  transition: all .3s;
  margin: 0 auto;
  position: relative;
  display: block;
  margin-top: 16px;

  span {
    position: absolute;
    display: inline-block;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    border-radius: 2px;
    -webkit-transition: all .4s;
    transition: all .4s;
    box-sizing: border-box;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 12px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
    &:nth-of-type(2)::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 2px;
      background-color: white;
      border-radius: 2px;
      -webkit-transition: all .4s;
      transition: all .4s;
    }
  }
}

.is-on {
  span {
    &:nth-of-type(2) {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      &::after {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
    &:nth-of-type(1) {
      -webkit-transform: translateY(20px) scale(0);
      -ms-transform: translateY(20px) scale(0);
      transform: translateY(20px) scale(0);
    }
    &:nth-of-type(3) {
      -webkit-transform: translateY(-20px) scale(0);
      -ms-transform: translateY(-20px) scale(0);
      transform: translateY(-20px) scale(0);
    }
  }
}