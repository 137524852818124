.u-align-center {
	text-align: center;
}

$num: 1;

@for $i from 0 through 10 {
  .mt#{$i * $num} {
    margin-top: #{$i * $num}rem !important;
  }
  .mr#{$i * $num} {
    margin-right: #{$i * $num}rem !important;
  }
  .mb#{$i * $num} {
    margin-bottom: #{$i * $num}rem !important;
  }
  .ml#{$i * $num} {
    margin-left: #{$i * $num}rem !important;
  }
  .pt#{$i * $num} {
    padding-top: #{$i * $num}rem !important;
  }
  .pr#{$i * $num} {
    padding-right: #{$i * $num}rem !important;
  }
  .pb#{$i * $num} {
    padding-bottom: #{$i * $num}rem !important;
  }
  .pl#{$i * $num} {
    padding-left: #{$i * $num}rem !important;
  }
}