@charset "UTF-8";
@font-face {
  font-family: 'Artegra-Sans';
  src: url("assets/fonts/Artegra_Sans-100-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Artegra-Sans';
  src: url("assets/fonts/Artegra_Sans-200-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Artegra-Sans';
  src: url("assets/fonts/Artegra_Sans-300-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

html {
  font-size: 10px;
  min-width: 100%;
}

body {
  font-size: 1.4rem;
  font-family: "DIN Condensed", Arial, Helvetica,  'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', "游ゴシック", YuGothic, Meiryo, sans-serif;
}

a {
  transition: all .3s ease;
  color: #147D3C;
}

a:hover {
  color: #147D3C;
  cursor: pointer;
}

.l-header {
  box-sizing: border-box;
  height: 80px;
  width: 100%;
  text-align: center;
  background-color: #fff;
  z-index: 10;
  position: fixed;
}

@media screen and (max-width: 768px) {
  .l-header {
    height: 60px;
  }
}

.l-header__icon-logo {
  position: fixed;
  left: 16px;
  top: 8px;
  z-index: 100;
  width: 40px;
  height: auto;
}

@media screen and (min-width: 769px) {
  .l-header__icon-logo {
    display: none;
  }
}

.l-header__lang {
  display: noe;
  font-size: 2rem;
  line-height: 60px;
  height: 60px;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .l-header__lang {
    display: none;
  }
}

.l-header__spmenu-btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .l-header__spmenu-btn {
    position: fixed;
    top: 0;
    right: 0;
    display: block;
    height: 60px;
    width: 60px;
    background-image: linear-gradient(0deg, #3d7b55 0%, #137639 100%);
    z-index: 100;
  }
}

.l-header__inner {
  box-sizing: border-box;
  width: 85%;
  height: 80px;
  max-width: 85%;
  margin: 0 auto;
  text-align: left;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .l-header__inner {
    display: none;
  }
  .l-header__inner.is-on {
    display: block;
    flex-direction: column;
    min-width: 100%;
    width: 100%;
    height: 100vh;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

.l-header__logo-wrapper {
  width: 25%;
  max-width: 25%;
  text-align: left;
}

.l-header__logo {
  width: 100%;
  min-width: 200px;
  max-width: 240px;
  vertical-align: middle;
}

.l-header__nav {
  text-align: right;
  white-space: nowrap;
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .l-header__nav {
    text-align: center;
    white-space: pre-line;
  }
}

.l-header__navlist {
  font-size: 2.4rem;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .l-header__navlist {
    margin-top: 80px;
  }
}

.l-header__navlist-item {
  display: inline-block;
  text-align: center;
  padding: 0 1rem;
  z-index: 3;
  position: relative;
  height: 80px;
  line-height: 80px;
  font-size: 2rem;
  letter-spacing: 0.120em;
}

@media screen and (max-width: 768px) {
  .l-header__navlist-item {
    line-height: 40px;
    height: 40px;
    color: white;
  }
}

.l-header__navlist-item a, .l-header__navlist-item span {
  color: #646464;
  text-decoration: none;
}

.l-header__navlist-item a:hover, .l-header__navlist-item span:hover {
  color: #147D3C;
  cursor: pointer;
}

.l-header__navlist-item:last-child {
  padding-right: 0;
}

.l-header__submenu {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 0;
  background: white;
  padding: 2rem;
  height: calc(100vh - 108px);
  background: linear-gradient(180deg, white 15%, rgba(255, 255, 255, 0) 30%);
}

.l-header__submenu-logo-wrapper::after {
  display: block;
  position: absolute;
  content: "";
  background: linear-gradient(0deg, #dbdef8 0%, #7080f1 100%);
  width: 75%;
  height: 3px;
  margin-top: 1em;
  opacity: 0;
  transition: .3s ease-in-out;
}

.l-header__submenu-logo-wrapper:hover::after {
  opacity: 1;
}

.l-header__submenu-logo {
  max-width: 100%;
  width: 85%;
}

.l-header__submenu-logo::after {
  display: block;
  content: "";
  background: linear-gradient(0deg, #dbdef8 0%, #7080f1 100%);
  width: 75%;
  height: 3px;
}

@media screen and (min-width: 769px) {
  .ls-header {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .ls-header {
    display: block;
    box-sizing: border-box;
    height: 66px;
    width: 100%;
    text-align: center;
    background-color: #fff;
    z-index: 10;
    position: fixed;
    top: 0;
  }
  .ls-header__inner {
    box-sizing: border-box;
    width: 85%;
    height: 66px;
    max-width: 85%;
    margin: 0 auto;
    text-align: left;
    display: inline-flex;
  }
  .ls-header__logo-wrapper {
    line-height: 100px;
    flex-grow: 1;
    line-height: 66px;
  }
  .ls-header__logo {
    height: 50px;
    width: auto;
    vertical-align: middle;
  }
  .ls-header__humburger {
    align-self: center;
  }
}

.l-main {
  padding-top: 80px;
}

@media screen and (max-width: 768px) {
  .l-main {
    padding-top: 66px;
  }
}

.l-footer {
  background: white;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  font-size: 1.6rem;
  letter-spacing: 0.120em;
  margin-top: 0;
  font-weight: bold;
}

.c-media {
  margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
  .c-media {
    margin-bottom: 4em;
  }
}

.c-media__img-wrapper {
  margin-bottom: 1em;
}

.c-media__img {
  width: 100%;
  height: auto;
}

.c-media__text {
  line-height: 1.7;
  font-size: 1.6rem;
  letter-spacing: 0.120em;
}

.c-btn {
  background-size: cover;
  display: inline-block;
  color: transparent;
  /*
	&--moongift {
		background-image: url(../images/img-btn-moongift.svg);
		width: 216px;
		height: 44px;
	}

	&--devrel {
		background-image: url(../images/img-btn-devrel.svg);
		width: 216px;
		height: 44px;
	}
	*/
}

.c-btn:hover {
  color: transparent;
}

.c-btn--viewmore {
  background-image: url(../images/img-btn-more.svg);
  width: 216px;
  height: 44px;
}

.c-btn--contact {
  background-image: url(../images/img-btn-contact.svg);
  width: 216px;
  height: 44px;
}

.c-btn--web {
  background-image: url(../images/img-btn-web.svg);
  width: 216px;
  height: 44px;
}

.c-btn--send {
  background-image: url(../images/img-btn-send.svg);
  width: 216px;
  height: 44px;
}

.c-table__row {
  height: 4em;
}

.c-table__th {
  padding: 0 0.5em;
  min-width: 100px;
}

.c-table__td, .c-table__th {
  letter-spacing: 0.120em;
  font-family: Arial, Helvetica,  'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', "游ゴシック", YuGothic, Meiryo, sans-serif;
}

@media screen and (max-width: 768px) {
  .c-table__th {
    font-size: 1.4rem;
  }
}

.c-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .c-sidebar {
    display: none;
  }
}

.c-sidebar__lang {
  color: white;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 2.6rem;
  letter-spacing: 0.120em;
  background-image: linear-gradient(0deg, #53a774 0%, #147d3c 100%);
}

.c-sidebar__lang span {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.c-sidebar__lang:hover {
  cursor: pointer;
}

.c-sidebar__logo {
  width: calc(80px/2);
  height: auto;
  margin: 60px 0 30% 0;
}

.c-sidebar__copyright {
  margin-bottom: 80px;
}

.c-sidebar__copyright, .c-sidebar__sns {
  writing-mode: vertical-rl;
  color: #646464;
  line-height: 80px;
}

.c-sidebar__twitter, .c-sidebar__facebook {
  margin: 1em 0;
}

.c-hamburger {
  width: 30px;
  height: 26px;
  transition: all .3s;
  margin: 0 auto;
  position: relative;
  display: block;
  margin-top: 16px;
}

.c-hamburger span {
  position: absolute;
  display: inline-block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  border-radius: 2px;
  -webkit-transition: all .4s;
  transition: all .4s;
  box-sizing: border-box;
}

.c-hamburger span:nth-of-type(1) {
  top: 0;
}

.c-hamburger span:nth-of-type(2) {
  top: 12px;
}

.c-hamburger span:nth-of-type(3) {
  bottom: 0;
}

.c-hamburger span:nth-of-type(2)::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background-color: white;
  border-radius: 2px;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.is-on span:nth-of-type(2) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.is-on span:nth-of-type(2)::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.is-on span:nth-of-type(1) {
  -webkit-transform: translateY(20px) scale(0);
  -ms-transform: translateY(20px) scale(0);
  transform: translateY(20px) scale(0);
}

.is-on span:nth-of-type(3) {
  -webkit-transform: translateY(-20px) scale(0);
  -ms-transform: translateY(-20px) scale(0);
  transform: translateY(-20px) scale(0);
}

.tp-hero {
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 80px);
}

.tp-hero__img-wrapper {
  align-self: center;
}

.tp-hero__logo {
  width: 309px;
  height: 628px;
}

@media screen and (max-width: 768px) {
  .tp-hero__logo {
    height: 240px;
    width: auto;
  }
}

.tp-section-header {
  position: relative;
  margin-bottom: 1em;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .tp-section-header {
    text-align: center;
    margin-bottom: 2em;
  }
}

.tp-section-header::before {
  background: linear-gradient(#53a774 0%, #147d3c 100%);
  width: 6px;
  height: 4em;
  content: "";
  display: inline-block;
  float: left;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .tp-section-header::before {
    display: none;
  }
}

.tp-section-header__title {
  font-size: 4rem;
  letter-spacing: 0.120em;
  line-height: 1.1;
  margin-bottom: 0;
}

.tp-section-header__title-ja {
  font-size: 1.2rem;
  letter-spacing: 0.120em;
  color: #646464;
}

.tp-section {
  padding: 4em 0;
}

.tp-section--gray {
  background-color: #f6f6f6;
}

.tp-section__text {
  line-height: 2;
  letter-spacing: 0.120em;
  font-size: 1.6rem;
}

.tp-section__text-dot {
  color: #147D3C;
}

.tp-section__text--pc {
  margin-bottom: 5rem;
}

@media screen and (max-width: 768px) {
  .tp-section--service, .tp-section--vision {
    padding-bottom: 0;
  }
}

.tp-section .c-btn {
  display: block;
  margin: 0 auto;
}

.tp-header {
  font-size: 2.4rem;
  letter-spacing: 0.120em;
  margin-bottom: 1em;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  .tp-header {
    font-size: 2rem;
    line-height: 1.8;
  }
}

.missionIcons {
  margin: 0 0 2rem 0;
}

.missionIcons__item {
  float: left;
  width: 23%;
  margin: 0 2.6% 0 0;
}

@media screen and (min-width: 769px) {
  .missionIcons__item {
    width: 20%;
    margin: 0 6.6% 0 0;
  }
}

.missionIcons__item:last-child {
  margin: 0;
}

.missionIcons__item dt {
  margin: 0 0 1rem 0;
}

.missionIcons__item dt img {
  max-width: 100%;
}

.missionIcons__item dd {
  text-align: center;
  font-size: 1.2rem;
}

.kaiketsu {
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  color: #147D3C;
  letter-spacing: 3px;
}

.serviceIcon {
  margin: 0 0 2rem 0;
}

.serviceIcon__item {
  float: left;
  width: 17%;
  margin-right: 3.75%;
}

.serviceIcon__item:first-child {
  margin-left: 0;
}

.serviceIcon__item:last-child {
  margin-right: 0;
}

.serviceIcon__item dt {
  margin: 0 auto 1rem auto;
  text-align: center;
}

.serviceIcon__item dt img {
  width: 50%;
}

.serviceIcon__item dd {
  text-align: center;
  line-height: 1.3;
}

.serviceIcon__en {
  font-size: 1.5rem;
  letter-spacing: 2px;
}

@media screen and (min-width: 769px) {
  .serviceIcon__en {
    font-size: 2.1rem;
    letter-spacing: 3px;
  }
}

.serviceIcon__en span {
  color: #147D3C;
}

.serviceIcon__ja {
  font-size: 1.1rem;
}

@media screen and (min-width: 769px) {
  .serviceIcon__ja {
    font-size: 1.4rem;
  }
}

.clientIcon__item {
  float: left;
  width: 28%;
  margin-right: 8%;
  margin-bottom: 4rem;
  text-align: center;
}

.clientIcon__item:nth-child(3n) {
  margin-right: 0;
}

.clientIcon__item dt {
  margin: 0 0 2rem 0;
}

.clientIcon__item dt img {
  max-width: 100%;
}

.clientIcon__item dd {
  text-align: left;
  height: 2rem;
  line-height: 1.3;
  font-size: 2.2vw;
}

@media screen and (min-width: 769px) {
  .clientIcon__item dd {
    font-size: 1.8rem;
  }
}

.blogHead {
  margin-bottom: 2rem;
}

@media screen and (min-width: 769px) {
  .blogHead {
    margin-top: 1rem;
    padding-top: 1rem;
    margin-bottom: 0;
    border-top: 2px dotted #aaa;
    display: flex;
    align-items: center;
  }
  .blogHead:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}

.blogHead__ph {
  width: 20%;
  float: left;
  margin-bottom: 0;
}

@media screen and (min-width: 769px) {
  .blogHead__ph {
    width: 95px;
  }
}

.blogHead__ph img {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .blogHead__ph img {
    width: 95px;
  }
}

.blogHead__detail {
  padding-left: 23%;
}

@media screen and (min-width: 769px) {
  .blogHead__detail {
    padding-left: 2rem;
  }
}

.blogHead__detail__title {
  line-height: 1.5;
  letter-spacing: 2px;
}

@media screen and (min-width: 769px) {
  .blogHead__detail__title {
    font-size: 2.4rem;
  }
}

.blogHead__detail__meta {
  padding: 0;
}

.blogHead__detail__date {
  color: #646464;
  font-size: 1.6rem;
  float: left;
  margin: 0 1rem 0 0;
  letter-spacing: 2px;
}

.blogHead__detail__cat {
  float: left;
  margin: 0 1rem 0 0;
}

.blogHead__detail__cat a {
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #646464;
  border-radius: 5px;
  font-size: 1.2rem;
  line-height: 1.2;
}

.blogHead__detail__cat a:hover {
  background-color: #147D3C;
  text-decoration: none;
}

.blogHead__detail li {
  list-style: none;
}

.blogList {
  margin-bottom: 2rem;
}

@media screen and (min-width: 769px) {
  .blogList {
    margin-top: 1.5rem;
    padding-top: 2rem;
    margin-bottom: 0;
    border-top: 2px dotted #aaa;
    display: flex;
    align-items: top;
  }
  .blogList:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}

.blogList__ph {
  width: 20%;
  float: left;
  margin-bottom: 0;
}

@media screen and (min-width: 769px) {
  .blogList__ph {
    width: 95px;
  }
}

.blogList__ph img {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .blogList__ph img {
    width: 95px;
  }
}

.blogList__detail {
  padding-left: 23%;
}

@media screen and (min-width: 769px) {
  .blogList__detail {
    padding-left: 2rem;
  }
}

.blogList__detail__title {
  line-height: 1.5;
  letter-spacing: 2px;
}

@media screen and (min-width: 769px) {
  .blogList__detail__title {
    font-size: 2.4rem;
  }
}

.blogList__detail__meta {
  padding: 0;
}

.blogList__detail__date {
  color: #646464;
  font-size: 1.6rem;
  float: left;
  margin: 0 1rem 0 0;
  letter-spacing: 2px;
}

.blogList__detail__cat {
  float: left;
  margin: 0 1rem 0 0;
}

.blogList__detail__cat a {
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #646464;
  border-radius: 5px;
  font-size: 1.2rem;
  line-height: 1.2;
}

.blogList__detail__cat a:hover {
  background-color: #147D3C;
  text-decoration: none;
}

.blogList__detail li {
  list-style: none;
}

.blogList__detail__description {
  display: none;
}

@media screen and (min-width: 769px) {
  .blogList__detail__description {
    display: block;
    clear: both;
    padding: 2rem 0 0 0;
    margin: 0;
    line-height: 2;
  }
}

.serviceCol {
  margin: 0 0 2rem 0;
}

@media screen and (min-width: 769px) {
  .serviceCol {
    margin: 0 0 4rem 0;
  }
}

.serviceCol__ph {
  width: 25%;
  margin: 0 auto 2rem auto;
}

@media screen and (min-width: 769px) {
  .serviceCol__ph {
    width: 69px;
    float: left;
    margin: 0;
  }
}

.serviceCol__ph img {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .serviceCol__detail {
    padding-left: 130px;
  }
}

.serviceCol__title {
  font-size: 2.5rem;
  letter-spacing: 2px;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .serviceCol__title {
    text-align: left;
  }
}

.serviceCol__title span {
  color: #147D3C;
}

.serviceCol__subTitle {
  text-align: center;
  font-size: 1.6rem;
}

@media screen and (min-width: 769px) {
  .serviceCol__subTitle {
    text-align: left;
  }
}

.priceCol {
  border-top: 1px solid #147D3C;
  margin-top: 2rem;
  padding-top: 2rem;
}

.priceCol:first-child {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

@media screen and (min-width: 769px) {
  .priceCol {
    margin-top: 4rem;
    padding-top: 4rem;
  }
  .priceCol:first-child {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
}

.priceCol__ph {
  display: none;
}

@media screen and (min-width: 769px) {
  .priceCol__ph {
    display: block;
    width: 69px;
    float: left;
    margin-bottom: 0;
  }
  .priceCol__ph img {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .priceCol__detail {
    padding-left: 100px;
  }
}

.priceCol__title {
  font-size: 2.5rem;
  letter-spacing: 2px;
  text-align: center;
  display: block;
}

@media screen and (min-width: 769px) {
  .priceCol__title {
    display: none;
  }
}

.priceCol__title span {
  color: #147D3C;
}

.priceCol__subTitle {
  text-align: center;
  font-size: 1.6rem;
}

@media screen and (min-width: 769px) {
  .priceCol__subTitle {
    text-align: left;
  }
}

.priceCol__price {
  text-align: center;
  font-size: 1.8rem;
}

@media screen and (min-width: 769px) {
  .priceCol__price {
    text-align: left;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.4;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 769px) {
  .cliantSec {
    margin-bottom: 4rem;
  }
}

.cliantCol {
  margin-bottom: 4rem;
}

@media screen and (min-width: 769px) {
  .cliantCol {
    width: 29%;
    margin: 0 6.5% 0 0;
    float: left;
  }
  .cliantCol:last-child {
    margin: 0;
  }
}

.cliantCol__ph {
  width: 60%;
  margin: 0 auto 1rem auto;
}

.cliantCol__ph img {
  max-width: 100%;
}

@media screen and (min-width: 769px) {
  .cliantCol__ph {
    width: 100%;
    margin: 0 0 3rem 0;
  }
  .cliantCol__ph img {
    max-width: 100%;
  }
}

.cliantCol__title {
  text-align: center;
  margin: 0 0 2rem 0;
}

@media screen and (min-width: 769px) {
  .cliantCol__title {
    height: 2em;
    margin: 0 0 3rem 0;
    text-align: left;
  }
}

.cliantCol__text {
  font-size: 1.6rem;
  line-height: 1.6;
}

.cliantCol__link {
  font-size: 1.6rem;
  line-height: 1.6;
}

.tablenav {
  text-align: center;
  margin: 2rem 0 5rem 0;
}

.tablenav .current {
  background: #147d3c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #147d3c 0%, #53a774 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #147d3c 0%, #53a774 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #147d3c 0%, #53a774 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: white;
}

.tablenav .next, .tablenav .prev {
  font-size: 1.6rem;
  height: 70px;
  line-height: 1;
}

.tablenav a.page-numbers {
  font-size: 1.6rem;
  padding: 1rem 1.2rem 0.5rem 1.2rem;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.tablenav a.page-numbers:first-child {
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #ccc;
}

.tablenav a.page-numbers:last-child {
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.tablenav a.page-numbers:hover {
  background: #147d3c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #147d3c 0%, #53a774 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #147d3c 0%, #53a774 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #147d3c 0%, #53a774 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: white;
  text-decoration: none;
}

.blogHeader {
  margin: 0 0 4rem 0;
}

@media screen and (min-width: 769px) {
  .blogHeader {
    padding: 0 0 2rem 0;
    border-bottom: 2px dotted #aaa;
  }
}

.blogHeader__title {
  line-height: 1.5;
}

@media screen and (min-width: 769px) {
  .blogHeader__title {
    font-size: 3.2rem;
  }
}

.blogHeader__meta {
  padding: 0;
  color: #646464;
  font-size: 1.6rem;
}

.blogHeader__meta__date {
  float: left;
  list-style: none;
  margin-right: 1rem;
}

.blogHeader__meta__cat {
  float: left;
  list-style: none;
  margin-right: 1rem;
}

.blogHeader__meta__cat a {
  font-size: 1rem;
  background-color: #646464;
  border-radius: 4px;
  padding: 0.8rem 1rem 0.4rem 1rem;
  color: #fff;
  line-height: 1;
}

.blogHeader__meta__cat a:hover {
  background-color: #147D3C;
  text-decoration: none;
}

.postBody {
  font-size: 1.8rem;
  line-height: 2;
}

.postBody p {
  margin-bottom: 1.5rem;
}

.postBody img {
  width: 100%;
  margin: 0 0 3rem 0;
}

.postBody strong {
  font-weight: bold;
}

.postBody em {
  font-style: italic;
  color: #777;
}

.postBody h2, .postBody h3 {
  font-weight: bold;
  line-height: 1.5;
  margin: 7rem 0 1.5rem 0;
  font-size: 2.4rem;
}

.postBody blockquote {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
}

.postBody blockquote p {
  margin-bottom: 0;
  padding: 0.4rem 0;
}

.notfound404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.notfound404 img {
  width: 50%;
  margin-bottom: 4rem;
}

.contact {
  margin-bottom: 4rem;
}

@media screen and (min-width: 769px) {
  .contact {
    margin-bottom: 20rem;
  }
}

.contact__th span {
  color: #a40000;
}

.contact__td {
  margin-bottom: 1rem;
}

.contact__area01 {
  border: 2px solid #147D3C;
  border-radius: 4px;
  width: 100%;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  height: 44px;
  line-height: 44px;
  margin: 0 0 0.5rem 0;
  font-size: 1.4rem;
}

.contact__area02 {
  border: 1px solid #999;
  width: 100%;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  height: 44px;
  line-height: 44px;
  margin: 0 0 0.5rem 0;
  font-size: 1.4rem;
}

.contact__area03 {
  border: 2px solid #147D3C;
  border-radius: 4px;
  width: 100%;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  height: 8em;
  line-height: 44px;
  margin: 0 0 0.5rem 0;
  font-size: 1.4rem;
}

.contact__errmsg {
  color: #a40000;
}

.u-align-center {
  text-align: center;
}

.mt0 {
  margin-top: 0rem !important;
}

.mr0 {
  margin-right: 0rem !important;
}

.mb0 {
  margin-bottom: 0rem !important;
}

.ml0 {
  margin-left: 0rem !important;
}

.pt0 {
  padding-top: 0rem !important;
}

.pr0 {
  padding-right: 0rem !important;
}

.pb0 {
  padding-bottom: 0rem !important;
}

.pl0 {
  padding-left: 0rem !important;
}

.mt1 {
  margin-top: 1rem !important;
}

.mr1 {
  margin-right: 1rem !important;
}

.mb1 {
  margin-bottom: 1rem !important;
}

.ml1 {
  margin-left: 1rem !important;
}

.pt1 {
  padding-top: 1rem !important;
}

.pr1 {
  padding-right: 1rem !important;
}

.pb1 {
  padding-bottom: 1rem !important;
}

.pl1 {
  padding-left: 1rem !important;
}

.mt2 {
  margin-top: 2rem !important;
}

.mr2 {
  margin-right: 2rem !important;
}

.mb2 {
  margin-bottom: 2rem !important;
}

.ml2 {
  margin-left: 2rem !important;
}

.pt2 {
  padding-top: 2rem !important;
}

.pr2 {
  padding-right: 2rem !important;
}

.pb2 {
  padding-bottom: 2rem !important;
}

.pl2 {
  padding-left: 2rem !important;
}

.mt3 {
  margin-top: 3rem !important;
}

.mr3 {
  margin-right: 3rem !important;
}

.mb3 {
  margin-bottom: 3rem !important;
}

.ml3 {
  margin-left: 3rem !important;
}

.pt3 {
  padding-top: 3rem !important;
}

.pr3 {
  padding-right: 3rem !important;
}

.pb3 {
  padding-bottom: 3rem !important;
}

.pl3 {
  padding-left: 3rem !important;
}

.mt4 {
  margin-top: 4rem !important;
}

.mr4 {
  margin-right: 4rem !important;
}

.mb4 {
  margin-bottom: 4rem !important;
}

.ml4 {
  margin-left: 4rem !important;
}

.pt4 {
  padding-top: 4rem !important;
}

.pr4 {
  padding-right: 4rem !important;
}

.pb4 {
  padding-bottom: 4rem !important;
}

.pl4 {
  padding-left: 4rem !important;
}

.mt5 {
  margin-top: 5rem !important;
}

.mr5 {
  margin-right: 5rem !important;
}

.mb5 {
  margin-bottom: 5rem !important;
}

.ml5 {
  margin-left: 5rem !important;
}

.pt5 {
  padding-top: 5rem !important;
}

.pr5 {
  padding-right: 5rem !important;
}

.pb5 {
  padding-bottom: 5rem !important;
}

.pl5 {
  padding-left: 5rem !important;
}

.mt6 {
  margin-top: 6rem !important;
}

.mr6 {
  margin-right: 6rem !important;
}

.mb6 {
  margin-bottom: 6rem !important;
}

.ml6 {
  margin-left: 6rem !important;
}

.pt6 {
  padding-top: 6rem !important;
}

.pr6 {
  padding-right: 6rem !important;
}

.pb6 {
  padding-bottom: 6rem !important;
}

.pl6 {
  padding-left: 6rem !important;
}

.mt7 {
  margin-top: 7rem !important;
}

.mr7 {
  margin-right: 7rem !important;
}

.mb7 {
  margin-bottom: 7rem !important;
}

.ml7 {
  margin-left: 7rem !important;
}

.pt7 {
  padding-top: 7rem !important;
}

.pr7 {
  padding-right: 7rem !important;
}

.pb7 {
  padding-bottom: 7rem !important;
}

.pl7 {
  padding-left: 7rem !important;
}

.mt8 {
  margin-top: 8rem !important;
}

.mr8 {
  margin-right: 8rem !important;
}

.mb8 {
  margin-bottom: 8rem !important;
}

.ml8 {
  margin-left: 8rem !important;
}

.pt8 {
  padding-top: 8rem !important;
}

.pr8 {
  padding-right: 8rem !important;
}

.pb8 {
  padding-bottom: 8rem !important;
}

.pl8 {
  padding-left: 8rem !important;
}

.mt9 {
  margin-top: 9rem !important;
}

.mr9 {
  margin-right: 9rem !important;
}

.mb9 {
  margin-bottom: 9rem !important;
}

.ml9 {
  margin-left: 9rem !important;
}

.pt9 {
  padding-top: 9rem !important;
}

.pr9 {
  padding-right: 9rem !important;
}

.pb9 {
  padding-bottom: 9rem !important;
}

.pl9 {
  padding-left: 9rem !important;
}

.mt10 {
  margin-top: 10rem !important;
}

.mr10 {
  margin-right: 10rem !important;
}

.mb10 {
  margin-bottom: 10rem !important;
}

.ml10 {
  margin-left: 10rem !important;
}

.pt10 {
  padding-top: 10rem !important;
}

.pr10 {
  padding-right: 10rem !important;
}

.pb10 {
  padding-bottom: 10rem !important;
}

.pl10 {
  padding-left: 10rem !important;
}
