@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mq-min($breakpoint-min: md) {
  @media #{map-get($breakpoints-min, $breakpoint-min)} {
    @content;
  }
}


@mixin bar {
	display: block;
	position: absolute;
	top: -340px;
	width: 4px;
	height: 280px;
	background: linear-gradient(rgb(86,190,223) 0%, rgb(38,77,159) 100%);
	transform: rotate(180deg);
	content: "";

	@include mq(){
	  top: -90px;
    width: 2px;
    height: 80px;
	}
}



@mixin bar-holizon {
	@include bar;
  top: 0px;
  width: 2px;
  height: 120px;
  margin-bottom: 0;
  position: static;
  display: inline-block;
	text-transform: rotate(90deg);
}



