.c-media {

	margin-bottom: 1em;

	@include mq() {
		margin-bottom: 4em;
	}

	&__img-wrapper {
		margin-bottom: 1em;
	}

	&__img {
		width: 100%;
		height: auto;
	}

	&__text {
		line-height: 1.7;
		font-size: 1.6rem;
		letter-spacing: 0.120em;
	}
}