.tp-hero {
	display: flex;
	justify-content: center;
	width: 100%;
	height: calc(100vh - #{$header-height});

	@include mq() {
		// height: calc (100vh - 66px);
	}

	&__img-wrapper {
		align-self: center;
	}

	&__logo {
		width: 309px;
		height: 628px;
		@include mq() {
			height: 240px;
			width: auto;
		}		
	}

}

.tp-section-header {
	position: relative;
	margin-bottom: 1em;
	white-space: nowrap;
	@include mq() {
		text-align: center;
		margin-bottom: 2em;
	}

	&::before {
		background: linear-gradient(rgb(83, 167, 116) 0%, rgb(20, 125, 60) 100%);
		width: 6px;
		height: 4em;
		content: "";
		display: inline-block;
		float: left;
		margin-right: 10px;
		
		@include mq() {
			display: none;
		}
	}

	&__title {
    font-size: 4rem;
    letter-spacing: 0.120em;
    line-height: 1.1;
    margin-bottom: 0;
	}

	&__title-ja {
		font-size: 1.2rem;
		letter-spacing: 0.120em;
		color: $color-secondary-text;
	}
}

.tp-section {
	padding: 4em 0;

	&--gray {
		background-color: $color-background-lightest;
	}

	&__text {
		line-height: 2;
		letter-spacing: 0.120em;
		font-size: 1.6rem;
		&-dot{
			color: $color-primary;
		}
		&--pc{
			margin-bottom: 5rem;
		}
	}

	&--service, &--vision {
		@include mq() {
			padding-bottom: 0;
		}
	}

	.c-btn {
		display: block;
		margin: 0 auto;
	}
}

.tp-header {
	font-size: 2.4rem;
	letter-spacing: 0.120em;
	margin-bottom: 1em;
	line-height: 1.6;
	@include mq() {
		font-size: 2rem;
		line-height: 1.8;
	}
}

.missionIcons{
	margin: 0 0 2rem 0;
	&__item{
		float: left;
		width: 23%;
		margin: 0 2.6% 0 0;
		@include mq-min(){
			width: 20%;
			margin: 0 6.6% 0 0;
		}
		&:last-child{
			margin: 0;
		}
		dt{
			margin: 0 0 1rem 0;
			img{
				max-width: 100%;
			}
		}
		dd{
			text-align: center;
			font-size: 1.2rem;
		}
	}
}//.missionIcons

.kaiketsu{
	text-align: center;
	font-weight: bold;
	font-size: 2rem;
	color: $color-primary;
	letter-spacing: 3px;
}

.serviceIcon{
	margin: 0 0 2rem 0;
	&__item{
		float: left;
		width: 17%;
		margin-right: 3.75%;
		&:first-child{
			margin-left: 0;
		}
		&:last-child{
			margin-right: 0;
		}
		dt{
			margin: 0 auto 1rem auto;
			text-align: center;
			img{
				width: 50%;
			}
		}
		dd{
			text-align: center;
			line-height: 1.3;
		}
	}
	&__en{
		font-size: 1.5rem;
		letter-spacing: 2px;
		@include mq-min(){
			font-size: 2.1rem;
			letter-spacing: 3px;
		}
		span{
			color: $color-primary;
		}
	}
	&__ja{
		font-size: 1.1rem;
		@include mq-min(){
			font-size: 1.4rem;
		}
	}
}//.missionIcons

.clientIcon{
	&__item{
		float: left;
		width: 28%;
		margin-right: 8%;
		margin-bottom: 4rem;
		text-align: center;
		&:nth-child(3n){
			margin-right: 0;
		}
		dt{
			margin: 0 0 2rem 0;
			img{
				max-width: 100%;
			}
		}
		dd{
			text-align: left;
			height: 2rem;
			line-height: 1.3;
			font-size: 2.2vw;
			@include mq-min(){
				font-size: 1.8rem;
			}
		}
	}
}//clientIcon


.blogHead{
	margin-bottom: 2rem;
	@include mq-min(){
		margin-top: 1rem;
		padding-top: 1rem;
		margin-bottom: 0;
		border-top: 2px dotted #aaa;
		display: flex;
		align-items: center;
		&:first-child{
			margin-top: 0;
			padding-top: 0;
			border-top: none;
		}
	}

	&__ph{
		width: 20%;
		float: left;
		margin-bottom: 0;
		@include mq-min(){
			width: 95px;
		}
		img{
			width: 100%;
			@include mq-min(){
				width: 95px;
			}
		}
	}//ph

	&__detail{
		padding-left: 23%;
		@include mq-min(){
			padding-left: 2rem;
		}
		&__title{
			line-height: 1.5;
			letter-spacing: 2px;
			@include mq-min(){
				font-size: 2.4rem;
			}
		}
		&__meta{
			padding: 0;
		}
		&__date{
			color: #646464;
			font-size: 1.6rem;
			float: left;
			margin: 0 1rem 0 0;
			letter-spacing: 2px;
		}
		&__cat{
			float: left;
			margin: 0 1rem 0 0;
			a{
				padding: 0.5rem 1rem;
				color: #fff;
				background-color: #646464;
				border-radius: 5px;
				font-size: 1.2rem;
				line-height: 1.2;
				&:hover{
					background-color: $color-primary;
					text-decoration: none;
				}
			}
		}
		li{
			list-style: none;
		}
	}//detail
}//blogHead


.blogList{
	margin-bottom: 2rem;
	@include mq-min(){
		margin-top: 1.5rem;
		padding-top: 2rem;
		margin-bottom: 0;
		border-top: 2px dotted #aaa;
		display: flex;
		align-items: top;
		&:first-child{
			margin-top: 0;
			padding-top: 0;
			border-top: none;
		}
	}

	&__ph{
		width: 20%;
		float: left;
		margin-bottom: 0;
		@include mq-min(){
			width: 95px;
		}
		img{
			width: 100%;
			@include mq-min(){
				width: 95px;
			}
		}
	}//ph

	&__detail{
		padding-left: 23%;
		@include mq-min(){
			padding-left: 2rem;
		}
		&__title{
			line-height: 1.5;
			letter-spacing: 2px;
			@include mq-min(){
				font-size: 2.4rem;
			}
		}
		&__meta{
			padding: 0;
		}
		&__date{
			color: #646464;
			font-size: 1.6rem;
			float: left;
			margin: 0 1rem 0 0;
			letter-spacing: 2px;
		}
		&__cat{
			float: left;
			margin: 0 1rem 0 0;
			a{
				padding: 0.5rem 1rem;
				color: #fff;
				background-color: #646464;
				border-radius: 5px;
				font-size: 1.2rem;
				line-height: 1.2;
				&:hover{
					background-color: $color-primary;
					text-decoration: none;
				}
			}
		}
		li{
			list-style: none;
		}
		&__description{
			display: none;
			@include mq-min(){
				display: block;
				clear: both;
				padding: 2rem 0 0 0;
				margin: 0;
				line-height: 2;
			}
		}
	}//detail
}//blogList


/////////////////////////////////////////
// subPages
/////////////////////////////////////////

.serviceCol{
	margin: 0 0 2rem 0;
	@include mq-min(){
		margin: 0 0 4rem 0;
	}
	&__ph{
		width: 25%;
		margin: 0 auto 2rem auto;
		@include mq-min(){
			width: 69px;
			float: left;
			margin: 0;
		}
		img{
			width: 100%;
		}
	}
	&__detail{
		@include mq-min(){
			padding-left: 130px;
		}
	}
	&__title{
		font-size: 2.5rem;
		letter-spacing: 2px;
		text-align: center;
		@include mq-min(){
			text-align: left;
		}
		span{
			color: $color-primary;
		}
	}
	&__subTitle{
		@include mq-min(){
			text-align: left;
		}
		text-align: center;
		font-size: 1.6rem;
	}
}//serviceCol


.priceCol{
	border-top: 1px solid $color-primary;
	margin-top: 2rem;
	padding-top: 2rem;
	&:first-child{
		border-top: none;
		margin-top: 0;
		padding-top: 0;
	}
	@include mq-min(){
		margin-top: 4rem;
		padding-top: 4rem;
		&:first-child{
			border-top: none;
			margin-top: 0;
			padding-top: 0;
		}
	}
	&__ph{
		display: none;
		@include mq-min(){
			display: block;
			width: 69px;
			float: left;
			margin-bottom: 0;
			img{
				width: 100%;
			}
		}
	}
	&__detail{
		@include mq-min(){
			padding-left: 100px;
		}
	}
	&__title{
		font-size: 2.5rem;
		letter-spacing: 2px;
		text-align: center;
		display: block;
		@include mq-min(){
			display: none;
		}
		span{
			color: $color-primary;
		}
	}
	&__subTitle{
		@include mq-min(){
			text-align: left;
		}
		text-align: center;
		font-size: 1.6rem;
	}
	&__price{
		text-align: center;
		font-size: 1.8rem;
		@include mq-min(){
			text-align: left;
			font-weight: bold;
			font-size: 2.4rem;
			line-height: 1.4;
			margin-bottom: 0;
		}
	}
}//priceCol


.cliantSec{
	@include mq-min(){
		margin-bottom: 4rem;
	}
}

.cliantCol{
	margin-bottom: 4rem;
	@include mq-min(){
		width: 29%;
		margin: 0 6.5% 0 0;
		float: left;
		&:last-child{
			margin: 0;
		}
	}
	&__ph{
		width: 60%;
		margin: 0 auto 1rem auto;
		img{
			max-width: 100%;
		}
		@include mq-min(){
			width: 100%;
			margin: 0 0 3rem 0;
			img{
				max-width: 100%;
			}
		}
	}
	&__title{
		text-align: center;
		margin: 0 0 2rem 0;
		@include mq-min(){
			height: 2em;
			margin: 0 0 3rem 0;
			text-align: left;
		}
	}
	&__text{
		font-size: 1.6rem;
		line-height: 1.6;
	}
	&__link{
		font-size: 1.6rem;
		line-height: 1.6;
	}
}//.cliantCol



.tablenav {
	text-align: center;
	margin: 2rem 0 5rem 0;
	.current {
		background: #147d3c; /* Old browsers */
		background: -moz-linear-gradient(top, #147d3c 0%, #53a774 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #147d3c 0%,#53a774 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #147d3c 0%,#53a774 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		color: white;
	}
	.next, .prev {
		font-size: 1.6rem;
		height: 70px;
		line-height: 1;
	}
	a.page-numbers {
		font-size: 1.6rem;
		padding: 1rem 1.2rem 0.5rem 1.2rem;
		border-right: 1px solid #ccc;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		&:first-child{
			-webkit-border-radius: 5px 0 0 5px;
			border-radius: 5px 0 0 5px;
			border: 1px solid #ccc;
		}
		&:last-child{
			-webkit-border-radius: 0 5px 5px 0;
			border-radius: 0 5px 5px 0;
			border-right: 1px solid #ccc;
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
		}
	}
	a.page-numbers:hover {
		background: #147d3c; /* Old browsers */
		background: -moz-linear-gradient(top, #147d3c 0%, #53a774 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #147d3c 0%,#53a774 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #147d3c 0%,#53a774 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		color: white;
		text-decoration: none;
	}
}//tableNav



.blogHeader{
	margin: 0 0 4rem 0;
	@include mq-min(){
		padding: 0 0 2rem 0;
		border-bottom: 2px dotted #aaa;
	}
	&__title{
		line-height: 1.5;
		@include mq-min(){
			font-size: 3.2rem;
		}
	}
	&__meta{
		padding: 0;
		color: #646464;
		font-size: 1.6rem;
		&__date{
			float: left;
			list-style: none;
			margin-right: 1rem;
		}
		&__cat{
			float: left;
			list-style: none;
			margin-right: 1rem;
			a{
				font-size: 1rem;
				background-color: #646464;
				border-radius: 4px;
				padding: 0.8rem 1rem 0.4rem 1rem;
				color: #fff;
				line-height: 1;
				&:hover{
					background-color: $color-primary;
					text-decoration: none;
				}
			}
		}
	}
}//blogHeader

.postBody{
	font-size: 1.8rem;
	line-height: 2;
	p{
		margin-bottom: 1.5rem;
	}
	img{
		width: 100%;
		margin: 0 0 3rem 0;
	}
	strong{
		font-weight: bold;
	}
	em{
		font-style: italic;
		color: #777;
	}
	h2,h3{
		font-weight: bold;
		line-height: 1.5;
		margin: 7rem 0 1.5rem 0;
		font-size: 2.4rem;
	}
	blockquote{
		background-color: #f9f9f9;
		padding: 2rem;
		border-radius: 10px;
		p{
			margin-bottom: 0;
			padding: 0.4rem 0;
		}
	}
}//.postBody

.notfound404{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	img{
		width: 50%;
		margin-bottom: 4rem;
	}
}

.contact{
	margin-bottom: 4rem;
	@include mq-min(){
		margin-bottom: 20rem;
	}
	&__th{
		span{
			color: #a40000;
		}
	}
	&__td{
		margin-bottom: 1rem;
	}
	&__area01{
		border: 2px solid $color-primary;
		border-radius: 4px;
		width: 100%;
		padding: 1rem 0.5rem 0.5rem 0.5rem;
		height: 44px;
		line-height: 44px;
		margin: 0 0 0.5rem 0;
		font-size: 1.4rem;
	}
	&__area02{
		border: 1px solid #999;
		width: 100%;
		padding: 1rem 0.5rem 0.5rem 0.5rem;
		height: 44px;
		line-height: 44px;
		margin: 0 0 0.5rem 0;
		font-size: 1.4rem;
	}

	&__area03{
		border: 2px solid $color-primary;
		border-radius: 4px;
		width: 100%;
		padding: 1rem 0.5rem 0.5rem 0.5rem;
		height: 8em;
		line-height: 44px;
		margin: 0 0 0.5rem 0;
		font-size: 1.4rem;
	}
	&__errmsg{
		color: #a40000;
	}
}//contact








