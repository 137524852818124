@font-face {
    font-family: 'Artegra-Sans';
    src: url('assets/fonts/Artegra_Sans-100-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Artegra-Sans';
    src: url('assets/fonts/Artegra_Sans-200-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Artegra-Sans';
    src: url('assets/fonts/Artegra_Sans-300-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}